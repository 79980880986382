import { useContext, useState } from "react";
import menuProvider from "../MenuContext";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../AuthContext";

const Login = () => {
  const { login } = useAuth();
  const { isOpen } = useContext(menuProvider);
  const navigate = useNavigate();
  const [visiblePassword, setVisiblePassword] = useState(false);

  const [formData, setFormData] = useState({
    userName: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState(""); // To store error messages

  const passwordVisibility = (e) => {
    e.preventDefault();
    setVisiblePassword((prev) => !prev);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrorMessage("");

    // Axios POST request
    axios
      .post("/login", formData)
      .then((res) => {
        if (res.status === 200) {
          login(res.data.token);
          setFormData({ userName: "", password: "" });
          console.log("Server response", res.data);
          navigate("/admin"); // Redirect to homepage after successful login
          console.log("form submitted");
        }
      })
      .catch((err) => {
        // Handle and display errors
        if (err.response && err.response.status === 400) {
          setErrorMessage(err.response.data.message);
        } else {
          setErrorMessage(err.response.data.message);
        }
        console.error("Error:", err);
      });
  };

  return (
    <div
      className={`${isOpen ? "opacity-0" : "opacity-100"}
       mt-[6rem] mb-[5rem] flex justify-center`}
    >
      <form
        onSubmit={handleSubmit}
        className="flex gap-4 flex-col justify-evenly items-center border-4
      bg-stone-300 border-gray-400 p-10 md:w-[25rem] h-[25rem]"
      >
        {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        {""}
        <input
          className=" p-1 shadow-inner shadow-black rounded w-full"
          type="text"
          name="userName"
          placeholder="UserName"
          value={formData.userName}
          onChange={handleChange}
          required
        />
        <input
          className=" p-1 shadow-inner shadow-black rounded w-full"
          type={visiblePassword ? "text" : "password"}
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleChange}
          required
        />
        <img
          className="w-[1.2rem] relative xxs:left-[5rem] xs:left-[3.8rem] xxs:bottom-[3.2rem] md:left-[8.6rem] md:bottom-[3.3rem] hover:cursor-pointer"
          src="/assets/password-eye.png"
          onClick={passwordVisibility}
        />
        <button className=" pl-4 pr-4 bg-red-500 ml-auto mr-auto p-2 rounded text-white active:bg-red-600 w-full ">
          Login
        </button>
        <div className=" text-gray-500 flex flex-col gap-2">
          <Link to="/Signup" className="hover:text-gray-200">
            Create Account
          </Link>
          <Link to="/Forgotpwd" className="hover:text-gray-200">
            Forgot Password?
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
