import { Link } from "react-router-dom";
import "../styles/animations.css";
import { useContext, useEffect } from "react";
import menuProvider from "./MenuContext";
import { useAuth } from "./AuthContext";

const Header = () => {
  const { isLogged, logout } = useAuth();
  const { isOpen, openMenu } = useContext(menuProvider);
  const closeMenu = () => {
    if (isOpen) {
      openMenu();
    }
  };

  const handleLogout = () => {
    if (isLogged) {
      logout();
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => document.body.classList.remove("overflow-hidden");
  }, [isOpen]);
  return (
    <nav
      className={` overscroll-none pt-[3rem] md:pt-8 flex items-baseline lg:ml-10 lg:mr-10 md:justify-between
     md:w-full lg:w-auto bg-gray-200 md:border-b-[.1rem] border-gray-400 md:pb-2`}
    >
      <div className="flex items-center xxs:ml-2 xs:ml-3 ">
        <span
          className="bg-gray-600 rounded-full md:w-6 md:h-6 lg:w-9 w-14 lg:h-9 h-14
         xxs:mr-1 xs:mr-3"
        ></span>
        <div className="flex items-baseline">
          <Link to="/">
            <h1
              className="font-bebas text-nowrap font-extrabold md:text-2xl lg:text-4xl text-3xl mr-3"
              onClick={closeMenu}
            >
              Daniel Ortega
            </h1>
          </Link>
          <h4 className="font-small flex-none">Developer</h4>
        </div>
      </div>
      <img
        src={`${isOpen ? "/assets/x-icon4.png" : "/assets/hamburger-icon.png"}`}
        className={`md:hidden cursor-pointer w-10 h-10 xxs:ml-5 xs:ml-10 ${
          isOpen ? "w-12 h-12" : "w-10"
        }`}
        onClick={openMenu}
      />
      <ul
        className={` md:mr-6 md:flex absolute md:relative top-32 md:top-0
        md:w-auto xxs:w-full pt-[11rem] md:pt-10 md:h-auto bg-gray-600 text-white md:text-black
         font-extrabold md:font-normal md:bg-inherit
         xxs:max-h-fit flex items-center flex-col md:flex-row md:gap-2 gap-6  lg:pb-0
        text-5xl md:text-base
        ${isOpen ? "block  fade-in-up md:animate-none pb-[20rem]" : "hidden"} md:block`}
        style={{ zIndex: 50 }}
      >
        {isLogged && (
          <li className="md:hover:text-orange-400 mr-4 md:mt-0 hover:cursor-pointer md:text-nowrap text-wrap">
            <Link to="/Admin" onClick={closeMenu}>
              Admin Portal
            </Link>
            <span className="ml-2 md:border-l-2 border-black"></span>
          </li>
        )}
        <li
          className={`md:hover:text-orange-400 ${isLogged ? "text-red-600" : ""} mr-4 md:mt-0 hover:cursor-pointer text-nowrap`}
        >
          <Link
            to="/Login"
            onClick={() => {
              handleLogout();
              closeMenu();
            }}
          >
            {isLogged ? `Sign Out` : "Login"}
          </Link>
          <span className="ml-2 md:border-l-2 border-black"></span>
        </li>
        <li className="md:hover:text-orange-400 hover:cursor-pointer">
          <Link to="/Contact" onClick={closeMenu}>
            Contact
          </Link>
          <span className="ml-2 md:border-l-2 border-black"></span>
        </li>
        <li className="md:hover:text-orange-400 ml-2 mr-2 hover:cursor-pointer">
          <Link to="/Resume" onClick={closeMenu}>
            Resume
          </Link>
          <span className="ml-2 md:border-l-2 border-black"></span>
        </li>
        <li className="md:hover:text-orange-400 hover:cursor-pointer">
          <Link to="/Projects" onClick={closeMenu}>
            Projects
          </Link>
          <span className="ml-2 md:-l-2 border-black"></span>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
