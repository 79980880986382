import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const ResetPassword = () => {
  const { token } = useParams(); // Extract token from URL
  const [isValidToken, setIsValidToken] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await axios.get(
          `https://danielortega.dev/ResetPassword/${token}`
        );
        if (response.data.message === "Token valid") {
          setIsValidToken(true);
        } else {
          setMessage("Invalid or expired token");
        }
      } catch (error) {
        setMessage("Error verifying token. Please try again...");
      }
    };

    verifyToken();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        "https://danielortega-dev.onrender.com/updatePassword",
        {
          token,
          password,
        }
      );

      setMessage("Password reset successfully!");
      navigate("/login");
    } catch (error) {
      setMessage("Something went wrong. Please try again.");
    }
  };

  if (!isValidToken) {
    return <p>{message}</p>; // Show message if token is invalid
  }

  return (
    <div className="flex justify-center mt-[10rem] mb-[5rem]">
      <form
        onSubmit={handleSubmit}
        className="flex gap-4 flex-col justify-evenly items-center border-4
      bg-stone-300 border-gray-400 p-10 w-fit h-25rem"
      >
        <input
          className=" p-1 shadow-inner shadow-black rounded"
          type="password"
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          className=" p-1 shadow-inner shadow-black rounded"
          type="password"
          placeholder="Confirm New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <button
          className=" pl-4 pr-4 bg-red-500 ml-auto mr-auto p-2 rounded text-white active:bg-red-600 "
          type="submit"
        >
          Update Password
        </button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ResetPassword;
