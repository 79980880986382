import React, { useContext, useEffect, useState } from "react";
import "../styles/animations.css";
import { Link } from "react-router-dom";
import menuProvider from "./MenuContext";

const Hero = () => {
  const { isOpen } = useContext(menuProvider);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={` ${
        isOpen ? "opacity-0" : " opacity-100"
      } fade-in-up flex justify-center md:pt-[30px]  lg:h-screen
      xxs:pt-[2rem] xs:pt-[7rem] lg:pt-[20rem] lg:pb-[10rem] mb-[10rem]`}
    >
      <div className=" flex items-center xxs:flex-col-reverse md:flex-row  gap-5">
        <img
          src="assets/profile-pic.JPG"
          className={`${
            scroll
              ? "block fade-In md:animate-none md:block"
              : "hidden md:block"
          } w-[22rem] h-[22rem] md:w-[20rem] md:h-[20rem] lg:w-[34rem] lg:h-[34rem] border-solid rounded-full object-cover
          mt-[2rem]`}
        />
        <div className="info-text mr-3 ml-3 md:mr-0 md:ml-5 md:w-[30rem] lg:w-[500px]">
          <h1 className=" text-8xl font-bold">Hello</h1>
          <h2 className=" font-bold text-2xl mt-5 md:mt-0 mb-2">
            A Bit About Me
          </h2>
          <h3 className=" font-medium font-serif pb-10 ">
            I’m Daniel Ortega, a MERN stack developer skilled in JavaScript,
            React, Node.js, Express, and MongoDB. I have a passion for
            transforming ideas into functional, dynamic, and user-friendly web
            applications. Whether it’s landing a full-time role or taking on
            freelance projects, I’m ready to showcase my skills and deliver real
            value to potential clients. I’m committed to producing high-quality
            work and am always excited to embrace new challenges that help me
            grow as a developer while driving impactful results. You can reach
            out to me directly by phone, email, or by using the Contact Me form
            on my website—I’d love to connect and discuss how I can help bring
            your vision to life!
          </h3>
          <div
            className={`${
              scroll ? "opacity-100" : "opacity-0"
            } flex gap-2 md:gap-5 justify-center`}
          >
            <span
              className={`${
                scroll ? "fade-in-up1" : "animate-none"
              } flex justify-center items-center border-solid
              md:hover:bg-white transition-colors duration-300 border-black border-2 bg-yellow-400 
            rounded-full w-[7rem] h-[7rem] md:w-36 md:h-36 text-center font-bold text-2xl`}
            >
              <Link to="/Resume">Resume</Link>
            </span>
            <span
              className={`${
                scroll ? "fade-in-up2" : "animate-none"
              } flex justify-center items-center border-solid
              md:hover:bg-white transition-colors duration-300 border-black border-2 bg-red-500
            rounded-full w-[7rem] h-[7rem] md:w-36 md:h-36 text-center font-bold text-2xl`}
            >
              <Link to="/Projects">Projects</Link>
            </span>
            <Link to="/Contact">
              <span
                className={`${
                  scroll ? "fade-in-up3" : "animate-none"
                } flex justify-center items-center border-solid
              md:hover:bg-white transition-colors duration-300 border-black border-2 bg-sky-300 
            rounded-full w-[7rem] h-[7rem] md:w-36 md:h-36 text-center font-bold text-2xl`}
              >
                Contact
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
