const Resume = () => {
  return (
    <div className=" flex gap-5 justify-center mt-[5rem] mb-[5rem] xxs:flex-col md:flex-row">
      <div className="ml-auto mr-auto">
        <a
          href="https://www.linkedin.com/in/daniel-ortega-74844194/"
          className="fade-In flex justify-center"
        >
          <img src="/assets/linkedin-icon.png" />
        </a>
        <img
          src="/assets/linkedin-resume.webp"
          className="md:w-[35rem] xxs:w-[25rem] rounded-md border-2 border-black"
        />
      </div>
      <div className="mr-auto ml-auto">
        <a
          href="https://www.linkedin.com/feed/?trk=guest_homepage-basic_google-one-tap-submit"
          className="fade-In flex justify-center"
        >
          <img src="/assets/indeed-icon2.png" />
        </a>
        <img
          src="/assets/indeed-resume.webp"
          className="md:w-[35rem] xxs:w-[25rem] rounded-md border-2 border-black"
        />
      </div>
    </div>
  );
};

export default Resume;
