import React, { useState } from "react";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents form from refreshing the page
    setLoading(true); // Set loading state when request is being made
    setMessage(""); // Clear any previous messages

    try {
      const response = await fetch("https://danielortega.dev/forgotPassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage("Reset link sent! Check your email.");
      } else {
        setMessage(data.message || "Something went wrong.");
      }
    } catch (error) {
      setMessage("Server error. Please try again later.");
    }

    setLoading(false); // Stop loading after request is complete
  };

  return (
    <div className="justify-center flex mt-[6rem] mb-[5rem]">
      <form
        className="flex gap-4 flex-col justify-evenly items-center border-4
      bg-stone-300 border-gray-400 p-10 w-[25rem] h-[25rem]"
        onSubmit={handleSubmit}
      >
        <input
          className=" p-1 shadow-inner shadow-black rounded w-full"
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)} // Update state when typing
          required
        />
        <button
          className=" pl-4 pr-4 bg-red-500 ml-auto mr-auto p-2 rounded text-white active:bg-red-600 w-full "
          type="submit"
          disabled={loading}
        >
          {loading ? "Sending..." : "Send Reset Link"}
        </button>
        {message}
      </form>
    </div>
  );
};

export default ForgotPassword;
