import "./App.css";
import Header from "./components/NavBar";
import Hero from "./components/hero";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Contact from "./components/pages/Contact";
import Projects from "./components/pages/Projects";
import Resume from "./components/pages/Resume";
import Login from "./components/pages/Login";
import menuProvider from "./components/MenuContext";
import Signup from "./components/pages/Signup";
import { AuthProvider } from "./components/AuthContext";
import Admin from "./components/pages/Admins";
import ProtectedRoute from "./utils/ProtectedRoutes";
import Forgotpwd from "./components/pages/Forgotpwd";
import PasswordRoute from "./utils/PasswordRoute";
import ResetPassword from "./components/pages/Reset-Password";
function App() {
  const [isOpen, setIsOpen] = useState(false);
  const openMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <menuProvider.Provider value={{ isOpen, setIsOpen, openMenu }}>
        <AuthProvider>
          <nav>{<Header />}</nav>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route path="/admin" element={<Admin />} />
            </Route>
            <Route path="/PasswordRoute/:token" element={<ResetPassword />} />
            <Route path="/" element={<Hero />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Projects" element={<Projects />} />
            <Route path="/Resume" element={<Resume />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Signup" element={<Signup />} />
            <Route path="/Forgotpwd" element={<Forgotpwd />} />
          </Routes>
          <footer>{<Footer />}</footer>
        </AuthProvider>
      </menuProvider.Provider>
    </div>
  );
}

export default App;
