import axios from "axios";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

const Signup = () => {
  const [serverMessage, setServerMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const toggleShow = (e) => {
    e.preventDefault();
    setShowPassword((prev) => !prev);
  };
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const Navigate = useNavigate();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    if (formData.confirmPassword === formData.password) {
      e.preventDefault();
      axios
        .post("https://danielortega.dev/addUser", formData)
        .then((res) => {
          setFormData({ userName: "", email: "", password: "" });
          Navigate("/Login");
          setServerMessage(res);
          console.log("server response", res);
        })
        .catch((err) => {
          setServerMessage(err);
          console.log(err);
        });
      console.log("Form Submitted!");
    } else {
      e.preventDefault();
      setServerMessage("passwords do not match");
    }
  };

  return (
    <div className="justify-center flex mt-[6rem] mb-[5rem]">
      <form
        className="flex gap-4 flex-col items-center border-4
      bg-stone-300 border-gray-400 p-10 w-[25rem] h-[25rem]"
        onSubmit={handleSubmit}
      >
        {serverMessage && <p className="text-red-500">{serverMessage}</p>}

        <input
          className="p-1 shadow-inner shadow-black rounded w-full"
          type="text"
          name="userName"
          placeholder="UserName"
          value={formData.userName}
          onChange={handleChange}
          required
        />
        <input
          className="p-1 shadow-inner shadow-black rounded w-full"
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <div className="w-full -mb-[1.5rem]">
          <input
            className="p-1 shadow-inner shadow-black rounded w-full relative"
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <img
            className="w-[1.2rem] relative xxs:left-[16.2rem] xs:left-[17.5rem] bottom-[1.7rem] hover:cursor-pointer"
            src="/assets/password-eye.png"
            onClick={toggleShow}
          />
        </div>
        <div className="w-full">
          <input
            className="p-1 shadow-inner shadow-black rounded w-full relative"
            type={showPassword ? "text" : "password"}
            name="confirmPassword"
            placeholder="Confirm Password"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
          <img
            className="w-[1.2rem] relative xxs:left-[16.2rem] xs:left-[17.5rem] bottom-[1.7rem] hover:cursor-pointer"
            src="/assets/password-eye.png"
            onClick={toggleShow}
          />
        </div>
        <button
          className=" w-full bg-red-500 ml-auto mr-auto p-2 rounded text-white"
          type="submit"
        >
          Create Account
        </button>
      </form>
    </div>
  );
};

export default Signup;
