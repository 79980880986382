import { useContext, useEffect } from "react";
import menuProvider from "../MenuContext";

const Projects = () => {
  const { isOpen } = useContext(menuProvider);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex justify-center items-center flex-col gap-5 pt-20">
      <div
        className={`${
          isOpen ? "opacity-0" : "opacity-100 transition-opacity duration-500"
        } flex justify-center xxs:flex-wrap gap-5 md:grid-cols-2 pt-[2rem] pb-[2rem] sm:h-[80rem] lg:w-[75rem]
    rounded-lg md:h-full lg:h-fit border-2 border-gray-300 bg-white`}
      >
        <div className="flex md:flex-row xxs:flex-col xxs:gap-5 lg:gap-20">
          <img
            src="/assets/littlelemon-1.webp"
            alt="little lemon landing page"
            className="fade-In xxs:w-[20rem] md:object-top md:object-contain md:h-[18.6rem] lg:h-full lg:w-[24.7rem]
    border-[.1rem] border-blue-800 rounded"
          />
          <img
            src="/assets/littlelemon-2.webp"
            alt="project image"
            className="fade-In xxs:w-[20rem] md:object-top md:object-fill lg:object-fill lg:w-[30rem] md:h-[18.6rem] lg:h-full
    border-[.1rem] border-blue-800 rounded"
          />
        </div>

        <h1 className=" bg-gray-50 border-[.1rem] border-yellow-400 rounded-lg p-5 font-serif text-m md:text-lg w-[25rem] md:w-fit md:h-fit lg:w-[50rem] text-center text-gray-800">
          I recently completed a web development project using React for a
          charming restaurant called Little Lemon. The website is designed to
          showcase the restaurant's unique ambiance and delicious menu
          offerings. Featuring a clean and modern layout, the site includes
          high-quality images, smooth navigation, and responsive design to
          ensure a seamless user experience on all devices. Visitors can easily
          explore the menu, learn about the restaurant's history, and make
          reservations. The project highlights my skills in React, responsive
          design, and creating visually appealing and user-friendly web
          applications.
        </h1>
      </div>
      <div
        className={`${
          isOpen ? "opacity-0" : "opacity-100 transition-opacity duration-500"
        } flex justify-center xxs:flex-wrap gap-5 md:grid-cols-2 pt-[2rem] pb-[2rem] xxs:w-full sm:h-[80rem] lg:w-[75rem]
    rounded-lg md:h-fit lg:h-full border-2 border-gray-300 bg-gray-50`}
      >
        <div className="flex xxs:flex-col md:flex-row xxs:gap-5 md:gap-20">
          <img
            src="/assets/806collision-1.png"
            className="xxs:w-[24rem] md:object-fill lg:w-[30rem] rounded-lg border-2 border-blue-800"
          />
          <img
            src="/assets/806collision-4.png"
            className="xxs:w-[24rem] md:object-contain lg:w-[30rem] rounded-lg border-2 border-blue-800"
          />
        </div>

        <div className="flex md:flex-row xxs:flex-col xxs:gap-5 md:gap-20">
          <img
            src="/assets/806collision-3.png"
            className=" h-full xxs:w-[24rem] md:object-fill lg:w-[30rem] md:h-[20rem] rounded-lg border-2 border-blue-800"
          />
          <img
            src="/assets/806screenshot2.png"
            className=" h-full xxs:w-[24rem] md:object-fill lg:w-[30rem] md:h-[20rem] rounded-lg border-2 border-blue-800"
          />
        </div>
        <p className=" text-center bg-gray-50 border-yellow-400 border-[.1rem] rounded-lg p-5 font-serif text-m md:text-lg w-[25rem] md:w-[50rem] text-cente text-gray-800">
          I created a sleek and functional website for an auto repair shop using
          Next.js, focusing on simplicity and delivering essential information
          effectively. The site features a clean design that highlights the
          shop's services, contact information, and operating hours, ensuring
          visitors can quickly find what they need. To enhance usability, I
          integrated Google Maps via its embedded API, allowing customers to
          locate the shop with ease. By leveraging Next.js's powerful framework,
          I optimized the site for fast performance and seamless navigation,
          showcasing a modern approach to web development while keeping the
          project lightweight and user-focused.
        </p>
      </div>
    </div>
  );
};

export default Projects;
