export default function Admin() {
  return (
    <div className="relative xxs:w-auto md:w-full h-screen xxs:mt-5 md:mt-0">
      <video
        muted
        autoPlay
        loop
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
        playsInline
      >
        <source src="/assets/cloud-video.mp4" type="video/mp4" />
      </video>
      <div className="relative z-10 flex items-center justify-center h-full">
        <h1 className="text-white text-4xl">Welcome Admins</h1>
      </div>
    </div>
  );
}
